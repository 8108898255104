@import url("https://fonts.googleapis.com/css?family=Roboto:300,400,600");

@import "~bulma/bulma";

html, body {
    font-family: 'Roboto', sans-serif;
}

#map {
    float: left;
    position: relative;
    margin: 1em;
    width: 800px;
    min-width: 800px;

    img {
        width: 100%;
        border: 1px dashed black;
    }
}

.controls {
    margin: 1em;
    float: right;
    position: relative;
    width: 30%;
}

.city {
    $size: 15px;

    border-radius: 50%;
    width: $size;
    height: $size;
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    color: white;
    font-size: .7em;
    padding: 0 .4em;
    background-color: grey;
    user-select: none;

    &.yellow {
        background-color: $warning;
        border-color: $warning;
        color: black;
    }

    &.red {
        background-color: $danger;
        border-color: $danger;
    }

    &.green {
        background-color: $success;
        border-color: $success;
    }

    &.blue {
        background-color: $info;
        border-color: $info;
    }

    &.epidemic-0 {
        color: transparent;
        opacity: .3;
    }

    &.epidemic-2 {
        transform: scale(1.4);
    }

    &.epidemic-3 {
        transform: scale(1.8);
    }
}

.time {
    h1 {
        margin-top: 1em;
        font-size: 5em;
    }
}